import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import RowText from '../widgets/RowText';
import Gallery from '../widgets/Gallery';
import Footer from '../layouts/Footer';
import Videos from '../widgets/Videos';

function SpecialProjectsPage(props) {

    return <>
        <Promo img="public/images/partners.jpg" />
        <Menu />
        <RowText textColumns={[
            "Группа компаний BY создает smart-решения для комфортный жизни в мегаполисе. Мы предлагаем продукты и сервисы, которые повышают качество жизни дома и на работе. Изучаем ваши желания и создаем то, что принесет радость и пользу.",
            "Особое место в экосистеме BY World занимают культурные и социальные проекты. Запускаем благотворительные сборы, организуем мероприятия в поддержку экологических и социальных инициатив, привлекаем внимание к глобальным задачам.",
            "Наш приоритет — поддержка российских талантов, поэтому мы открываем новые площадки и возможности для самовыражения. У нас уже запущены интеграции с киноиндустрией, есть театральные проекты, иммерсивные программы в стиле BY — и это только начало!"
        ]} title="Специальные проекты" />
        <Gallery slides={["projects/1.jpeg", "projects/2.jpeg", "projects/3.jpeg", "projects/4.jpeg", "projects/5.jpeg", "projects/6.jpeg", "projects/7.jpeg", "projects/8.jpeg", "projects/9.jpeg", "projects/10.jpeg", "projects/11.jpeg", "projects/12.jpeg", "projects/13.jpg", "projects/14.jpeg", "projects/15.jpeg", "projects/16.jpeg", "projects/17.jpeg", "projects/18.jpeg"]} />
        <Videos slides={["https://www.youtube.com/embed/yc_ZAYucndU", "https://www.youtube.com/embed/bcWyCcEmNRU", "https://www.youtube.com/embed/whEziJWF_rA", "https://www.youtube.com/embed/v0mZYaQ2sl0", "https://www.youtube.com/embed/EqDY99Dj3wI", "https://www.youtube.com/embed/ui86W3nDKp0", "https://www.youtube.com/embed/nYvJ7gRPPH0", "https://www.youtube.com/embed/-nxgzmgcNds", "https://www.youtube.com/embed/MyM9Z2sZl34", "https://www.youtube.com/embed/Cr-q3hWkVTQ", "https://www.youtube.com/embed/3TBpxTfJSOA", "https://www.youtube.com/embed/bJithDkTJq4", "https://www.youtube.com/embed/98-Ne6SrDKE", "https://www.youtube.com/embed/lu6fO0UnGJ8"]} />
        <Footer />
    </>
}

export default SpecialProjectsPage