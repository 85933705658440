import React from 'react';
import Footer from '../layouts/Footer';
import HeroZarya from '../widgets/HeroBlock/zarya';
import Menu from '../widgets/Menu';

function ZaryaPage(props) {
    return <>
        <HeroZarya />
        <Menu />
        <Footer />
    </>
}

export default ZaryaPage