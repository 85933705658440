import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import RowText from '../widgets/RowText';
import Gallery from '../widgets/Gallery';
import Footer from '../layouts/Footer';
import Videos from '../widgets/Videos';

function MotosportPage(props) {

    return <>
        <Promo img="public/images/motosport.jpg" />
        <Menu />
        <RowText textColumns={[
            "BY Motorsport — профессиональная гоночная команда по дрифту, представляющая наш бренд на трассах по всей России. Символ нашей команды — эпичные и современные супергерои.",
            "Мы популяризируем дрифт, спорт, активный образ жизни. BY Motorsport с супергеройской символикой стал самый ярким участником Российской дрифт-серии «Russian Drift Series Gran Prix» 2021.",
            "Организуем стильные фан-зоны в стиле BY и вдохновляем болельщиков. А победы команды доказывают — Russian Super Heroes способны на все!"
        ]} title="BY Motorsport" />
        <Gallery slides={["motosport/1.jpeg", "motosport/2.jpg", "motosport/3.jpeg", "motosport/4.jpeg", "motosport/5.jpeg", "motosport/6.jpg", "motosport/7.jpeg", "motosport/8.jpeg", "motosport/9.jpeg", "motosport/10.jpeg", "motosport/11.jpeg", "motosport/12.jpeg", "motosport/13.jpeg", "motosport/14.jpeg", "motosport/15.jpeg"]} />
        <Videos slides={["https://www.youtube.com/embed/rTfA3gRdAew"]} />
        <Footer />
    </>
}

export default MotosportPage