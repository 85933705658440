import React from 'react';
import './index.scss';
import './gorinich.scss';
import HeroBanner from '../HeroBanner';
import HeroSeparator from '../HeroSeparator';

function HeroGorinich(props) {

    return <>
        <HeroBanner
            img_desktop="public/images/heroes/gorinich/gorinich_desktop.png"
            img_tablet="public/images/heroes/gorinich/gorinich_desktop.png"
            img_mobile="public/images/heroes/gorinich/gorinich_mobile.png"
        />
        <section className="gorinich-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col hero-row__col_fluid">
                        <img src="public/images/heroes/gorinich/gorinich_skills.png" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>МОЖЕТ ЛЕТАТЬ В КОСМОСЕ</li>
                            <li>СУПЕР НАНОТЕХНОЛОГИЧНАЯ НЕПРОБИВАЕМАЯ ЧЕШУЯ</li>
                            <li>ПОЛЬЗУЕТСЯ ВСЕМИ СИСТЕМЫ НАВИГАЦИИ, GPRS, АВТОПИЛОТОМ, СИСТЕМАМИ ОПОЗНОВАНИЯ И НАВЕДЕНИЯ НА ОБЪЕКТЫ</li>
                            <li>ИСПОЛЬЗУЕТ РАДАРЫ И СРЕДСТВА ЗАЩИТЫ ОТ СОВРЕМЕННОГО ОРУЖИЯ</li>
                            <li>ОБЛАДАЕТ ВСЕМИ ТИПАМИ СВЯЗЕЙ С КОЩЕЕМ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/gorinich/gorinich_separator.png" />
        <section className="history history_gorinich">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Кощей долго искал, выбирал и нашел три уникальных человека с отсутствием будущего, семьи и надеждой на существование, приручил, объединил и породнил их: Безутешного, отвязного, наглого, но ироничного убийцу/киллера — Восточный герой Кавказа;Восточного левитирующего дзен-монаха с отличительными знаками на теле, достигшего девятой степени просветления и желавшего покинуть бренный мир навсегда.Сибирский крестьянин — одинокий, немой, сын сокола и человека. С невероятным зрением, с поражением основных нижниx функций двигательного аппарата. Несмотря на абсолютно разные мотивы и xарактеры их триединство дает Кащею возможность созидания невероятного по силе и мощи существа.  Если одного из персонажей не хватает в момент объединения — нет и Горыныча.</p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/gorinich/gorinich_separator.png" />
        <section className="visual visual_gorinich">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-1.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_1">Летающий монстр</h4>
                        <p>
                            Горыныч был создан из трех потерянных душ
                            и сущностей людей. При соединении треx избранныx и формировании определенного эгрегора  доступного только Кощею, они превращались  в трехголового летающего монстра, обладающего силой целыx армий.
                        </p>
                        <img src="public/images/heroes/gorinich/gorinich-info-2.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_2">Сарацин ГОР</h4>
                        <p>
                            Сарацин по имени ГОР – убийца-каторжник, мастер меча и огненных иллюзий по призванию, обладающий силой огня.
                        </p>
                        <img src="public/images/heroes/gorinich/gorinich-info-3.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-4.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-5.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_3">Монах Ы</h4>
                        <p>
                            Монах, прошедший путь от брахмана до перевоплощения будды – познавший науку левитации и перемещений в воздухе по восточному имени Ы.
                        </p>
                        <img src="public/images/heroes/gorinich/gorinich-info-6.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_4">Птицечеловек НЫЧ</h4>
                        <p>
                            Птицечеловек с острым взглядом и слухом, которого звали странным именем НЫЧ. Соединив их имена воедино, и вышло название знаменитого героя мифологии Горыныч.
                        </p>
                        <img src="public/images/heroes/gorinich/gorinich-info-7.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-8.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-9.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_5">Троица Горыныч</h4>
                        <p>
                            Несметное количество войск полегло от огня летающего и всеразрушающего чудища. Кощею не было необходимости применять прочие технологии в битвах. Всегда было достаточно выпустить одного хищного зверя и дело решено. Тысяча жертв как косой срезал Горыныч и не было на него спасу. Кощей и троица Горыныч остаются неразлучными по сей день.
                        </p>
                        <img src="public/images/heroes/gorinich/gorinich-info-10.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__block">
                        <img src="public/images/heroes/gorinich/gorinich-info-11.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__block">
                        <img src="public/images/heroes/gorinich/gorinich-info-12.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__block">
                        <img src="public/images/heroes/gorinich/gorinich-info-13.webp" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <section className="visual visual_grey">
            <div className="container">
                <div className="hero-row ">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-14.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-15.webp" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section className="visual visual_gorinich">
            <div className="container">
                <div className="hero-row ">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-16.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/gorinich/gorinich-info-17.webp" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <br />
        <HeroBanner
            img_desktop="public/images/heroes/gorinich/gorinich-footer.png"
            img_tablet="public/images/heroes/gorinich/gorinich-footer.png"
            img_mobile="public/images/heroes/gorinich/gorinich-footer.png"
        />
    </>
}

export default HeroGorinich