import React from 'react';
import Footer from '../layouts/Footer';
import Menu from '../widgets/Menu';
import RowText from '../widgets/RowText';
import Hero from '../widgets/Hero';

import Promo from '../widgets/Promo';
import Videos from '../widgets/Videos';

function CompanyPage(props) {
    const info = [
        {
            title: "Миссия BY \n BY — будь уверен!",
            description: "Миссия BY — создание продуктов и сервисов, формирующих высокий уровень жизни российского потребителя: уверенного в себе, своем социальном статусе и комфорте.",
            theme: 'hero_center hero_dark'
        },
        {
            title: "Ценности BY \n Человеческий капитал",
            description: "Уважительно относимся к человеческим знаниям и навыкам, как к достоянию страны. Создаем рабочие места, проводим благотворительные проекты в помощь малообеспеченным и слабозащищенным гражданам. Технологии, инновации, экология Разрабатываем и используем инновационные технологии в создании товаров для жизни: бытовой техники, гаджетов, одежды. В производстве и реализации продукции используем экологичные материалы и упаковку, контролируем защиту окружающей среды. Для нас важно улучшать пространство для жизни, сохраняя природу России и мира. Культурное и историческое наследие. Мы сохраняем и развиваем уникальный культурный код страны. Наша концепция — синтез истории, фольклора, особенностей менталитета и географии России, разработанная для разумного потребления качественных товаров и услуг. Супергеройская символика позволяет нам заинтересовать культурным кодом разные поколения и объединить всех членов семьи.",
            theme: 'hero_center'
        },
    ]

    return <>
        <Promo img="public/images/company_banner.jpg" />
        <Menu />
        <RowText textColumns={[
            "BY — группа компаний с качественными товарами, услугами и проектами от Калининграда до Владивостока. Мы производим и реализуем высококлассные гаджеты, технику и товары для повседневной жизни, спонсируем спортивные и культурные шоу, организуем модные показы, продюсируем съемки клипов и кино, поддерживаем таланты и благотворительные акции.",
            "Все направления работы BY объединяет идея сохранения культурного кода России и развития общества. В нашей стране мы производим нужные современные продукты и обеспечиваем рабочие места для россиян.",
            "Наш символ — супергерои, выросшие из русского фольклора. В них наш менталитет и достоинство слились с высокими технологиями, чувством стиля, ритмом мегаполиса, умением принимать решения. Они, как BY: уверены в себе и с каждой задачей становятся только сильнее. Всегда"
        ]} title="О компании BY" />

        {info.map((el) => {
            return <Hero
                title={el.title}
                description={el.description}
                theme={el.theme}
                url={el.url}
                img={el.img}
            />
        })}
        <Videos slides={["https://www.youtube.com/embed/fg5B5dU351g"]} />
        <Footer />
    </>
}

export default CompanyPage