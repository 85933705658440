import React from 'react';
import Footer from '../layouts/Footer';
import HeroVasilisa from '../widgets/HeroBlock/vasilisa';
import Menu from '../widgets/Menu';

function VasilisaPage(props) {
    return <>
        <HeroVasilisa />
        <Menu />
        <Footer />
    </>
}

export default VasilisaPage