import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import RowText from '../widgets/RowText';
import Gallery from '../widgets/Gallery';
import Footer from '../layouts/Footer';
import Videos from '../widgets/Videos';

function FashionPage(props) {

    return <>
        <Promo img="public/images/fashion.jpg" />
        <Menu />
        <RowText textColumns={[
            "Создаем коллекции современной уличной моды в коллаборации с молодыми российскими дизайнерами. Вдохновляемся супергероями BY, чтобы создавать эклектичные и дерзкие сочетания.",
            "Из микса фольклорных деталей, эпичных подвигов и суперсилы создана коллекция BY STREETcouture. Одежда и аксессуары для того, кто стремится быть ярким, стильным. Но! Ставит комфорт на первое место.",
            "BY STREETcouture уже задает высокую планку в кино и на телевидении. Мерч BY STREETcouture принял участие в создании топовых проектов российского кинематографа — драмы «Девятаев» и комедии «Елки» Тимура Бекмамбетова."
        ]} title="Мода" />
        <Gallery slides={["Fashion/1.jpeg", "Fashion/2.jpeg", "Fashion/3.jpeg", "Fashion/4.jpg", "Fashion/5.jpg","Fashion/6.jpg","Fashion/7.jpg","Fashion/8.jpg","Fashion/9.jpg","Fashion/10.jpg","Fashion/11.jpg","Fashion/12.jpg"]} />
        <Videos slides={["https://www.youtube.com/embed/eJaAb3cqfxA", "https://www.youtube.com/embed/jz7xnZtD10M"]} />

        <Footer />
    </>
}

export default FashionPage