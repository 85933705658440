import React from 'react';
import './index.scss';
import './vasilisa.scss';
import HeroBanner from '../HeroBanner';
import HeroSeparator from '../HeroSeparator';

function HeroVasilisa(props) {

    return <>
        <HeroBanner
            img_desktop="public/images/heroes/vasilisa/vasilisa_desktop.png"
            img_tablet="public/images/heroes/vasilisa/vasilisa_desktop.png"
            img_mobile="public/images/heroes/vasilisa/vasilisa_mobile.png"
        />
        <section className="vasilisa-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col hero-row__col_fluid">
                        <img src="public/images/heroes/vasilisa/vasilisa_skills.png" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>ТЕЛЕКИНЕЗ</li>
                            <li>ЧИТАЕТ МЫСЛИ</li>
                            <li>ГИПНОТИЧЕСКАЯ КРАСОТА</li>
                            <li>МИСТИЧЕСКОЕ ВЛИЯНИЕ НА СОЗНАНИЕ</li>
                            <li>ФРАГМЕНТАЛЬНОЕ ВИДЕНИЕ БУДУЩЕГО, ВЕЩИЕ СНЫ</li>
                            <li>ПОГРУЖЕНИЕ ЛЮДЕЙ В АЛЬТЕРНАТИВНЫЕ РЕАЛЬНОСТИ</li>
                            <li>УПРАВЛЕНИЕ НА НЕБОЛЬШИХ ПРОСТРАНСТВАХ ПРИРОДНЫМИ СТИХИЯМИ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/vasilisa/vasilisa_separator.png" />
        <section className="history history_vasilisa">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Василиса — Дочь Яги и Ермака. Целеустремленная, высокая харизма, невероятная, гипнотическая красота, прозорливый ум, влюбчива, заботлива, решительна, любит все функциональное от одежды до результата. Травница, лекарь как и мать, тяга к новым наукам: био и нейрохакинг, развитие внутреннего потенциала человека, астрология, эпигенетика, фрактальность, прочие тайны Вселенной. Выросла в избушке у Яги без отца. Отец ушел за подвигами. Однажды ушла из дома в люди, была предводительницей разныx племен, именовалась Царицей. Помогала своему отцу Ермаку осваивать новые земли. </p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/vasilisa/vasilisa_separator.png" />
        <section className="visual visual_vasilisa">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/vasilisa/vasilisa-info-1.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4  className="heading heading_gradient_1">Дочь морского царя</h4>
                        <p>
                            В древней русской мифологии Василиса Прекрасная или Премудрая дочь морского царя, есть предположение, учитывая способности управление водой Ермаком – что версия происxождения Василисы верна.
                        </p>
                        <img src="public/images/heroes/vasilisa/vasilisa-info-2.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_2">Чудо-очки</h4>
                        <p>
                            При всей негативности — двигатель прогресса  и цивилизации. Биткоины, NFT социальные сети.
                        </p>
                        <img src="public/images/heroes/vasilisa/vasilisa-info-3.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/vasilisa/vasilisa-info-4.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/vasilisa/vasilisa-info-5.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_3">Кукла оберег</h4>
                        <p>
                            У Василисы есть волшебная куколка, которая досталась ей от матери. Эта кукла её оберег, помощница и советчица.
                        </p>
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">

                        <img src="public/images/heroes/vasilisa/vasilisa-info-6.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/vasilisa/vasilisa-info-7.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">

                        <img src="public/images/heroes/vasilisa/vasilisa-info-8.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/vasilisa/vasilisa-info-9.webp" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <br />
        <HeroBanner
            img_desktop="public/images/heroes/vasilisa/vasilisa-footer.png"
            img_tablet="public/images/heroes/vasilisa/vasilisa-footer.png"
            img_mobile="public/images/heroes/vasilisa/vasilisa-footer.png"
        />
    </>
}

export default HeroVasilisa