import React from 'react';
import Footer from '../layouts/Footer';
import HeroKoshey from '../widgets/HeroBlock/koshey';
import Menu from '../widgets/Menu';

function KosheyPage(props) {
    return <>
        <HeroKoshey />
        <Menu />
        <Footer />
    </>
}

export default KosheyPage