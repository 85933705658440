
import React from 'react';
import './index.scss';
import './leshiy.scss';
import HeroBanner from '../HeroBanner';
import HeroSeparator from '../HeroSeparator';

function HeroLeshiy() {
    return <>
        <HeroBanner
            img_desktop="public/images/heroes/leshiy_desktop.jpg"
            img_tablet="public/images/heroes/leshiy_desktop.jpg"
            img_mobile="public/images/heroes/leshiy_mobile.jpg"
        />
        <section className="leshiy-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col hero-row__col_fluid">
                        <img src="public/images/heroes/leshiy/leshiy-skills.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>ОБЩАЕТСЯ С ЖИВОТНЫМИ</li>
                            <li>НЕЗАМЕТНОСТЬ И НЕВИДИМОСТЬ</li>
                            <li>ПЕРЕНОСИТСЯ В ПРОСТРАНСТВЕ СО СКОРОСТЬЮ ВЕТРА</li>
                            <li>ПУТАЕТ СОЗНАНИЕ ПРОТИВНИКОВ</li>
                            <li>ПЕРЕМЕЩАЕТ СОЗНАНИЕ И ТЕЛО АППОНЕНТА В РАЗЛИЧНЫЕ ПРОСТРАНСТВА И ИЗМЕРЕНИЯ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/leshiy/leshiy-separator.png" />
        <section className="history history_leshiy">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Вечный изгой эпоса и мифологии не так-то прост и не случайно оказался в пантеоне Вселенной BY.Когда человечество стало совершать безумные поступки по отношению к планете, Леший взял на себя тяжелую ношу спасителя супергероя!Это он повлиял на создание Greenpeace после ядерных испытаний в середине 20-ого века.</p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/leshiy/leshiy-separator.png" />
        <section className="leshiy-info">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/leshiy/leshiy-info-1.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_1">Защитник пророды</h4>
                        <p>Леший – защитник природы, самый древний дух Земли из известных. Вся история, все события и тайны не прошли мимо него.</p>
                        <img src="public/images/heroes/leshiy/leshiy-info-2.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/leshiy/leshiy-info-3.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/leshiy/leshiy-info-4.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">

                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_2">Главный архивариус знаний на Планете</h4>
                        <p>Знает язык деревьев и растений, секреты зеленой Планеты, умеет управлять окружающим миром и охраняет Природу. </p>
                        <img src="public/images/heroes/leshiy/leshiy-info-5.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/leshiy/leshiy-info-6.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/leshiy/leshiy-info-7.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/leshiy/leshiy-info-8.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__block">
                        <img src="public/images/heroes/leshiy/leshiy-info-9.jpg" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <br />
        <HeroBanner
            img_desktop="public/images/heroes/leshiy/leshiy-footer.jpg"
            img_tablet="public/images/heroes/leshiy/leshiy-footer.jpg"
            img_mobile="public/images/heroes/leshiy/leshiy-footer.jpg"
        />
    </>
}

export default HeroLeshiy