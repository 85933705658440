import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import RowText from '../widgets/RowText';
import Gallery from '../widgets/Gallery';
import Footer from '../layouts/Footer';

function ElectronicPage(props) {

    return <>
        <Promo img="public/images/electronicBanner.png" />
        <Menu />
        <RowText textColumns={[
            "Быстроразвивающаяся российская образовательно - развлекательная платформа, объединяющая в себе спектр как креативных индустрий, так и производственно-торговых направлений, позволяющий развивать, вдохновлять и информировать общество.",
            "Вместе со своими дочерними и аффилированными компаниями BY World (BY Studio, BY Enterteiment, BY Gastro, BY Universe BY Hall), BY Universe, BY Fund и BY Shop & сеть ритейл маркетов по всей стране для семьи и дома опережает актуальные запросы и тренды современного мира и отвечает на новые вызовы времени и прогресса.",
            "Интеграция в сознание людей уверенности в себе, уверенности в окружающем мире, создание знака наивысшего качества во всех сферах деятельности и, как следствие, создание нового сверхпоколения – поколения BY!"
        ]} title="Заголовок" />
        <Gallery slides={["1.png", "2.png", "3.png"]} />
        <Footer />
    </>
}

export default ElectronicPage