import React from 'react';
import './index.scss';
import './koshey.scss';
import HeroBanner from '../HeroBanner';
import HeroSeparator from '../HeroSeparator';

function HeroKoshey(props) {

    return <>
        <HeroBanner
            img_desktop="public/images/heroes/koshey/koshey_desktop.png"
            img_tablet="public/images/heroes/koshey/koshey_desktop.png"
            img_mobile="public/images/heroes/koshey/koshey_mobile.png"
        />
        <section className="koshey-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col hero-row__col_fluid">
                        <img src="public/images/heroes/koshey/koshey_skills.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>БЕССМЕРТИЕ</li>
                            <li>КЛОНИРОВАНИЕ</li>
                            <li>МОЛНИЕНОСНОЕ ПЕРЕМЕЩЕНИЕ В ПРОСТРАНСТВЕ</li>
                            <li>ПЕРЕДОВЫЕ ЗНАНИЯ И ТЕХНОЛОГИИ ВО ВСЕХ СФЕРАХ</li>
                            <li>УКРОЩЕНИЕ ЗВЕРЕЙ (ВОЛКИ-ОБОРОТНИ, ГУСИ-ЛЕБЕДИ Т.Д.)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/koshey/koshey_separator.png" />
        <section className="history history_koshey">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Полубог.  Сын языческих богов — Чернобога (злой бог несчастья и разрушений) и Марена (мифо-ритуальный персонаж, воплощение смерти и зимы). Кощей — чаще всего упоминается в русском эпосе как злодей, негативный персонаж. На самом деле — сложный образ — ученый, алхимик, естествоиспытатель.
                    С детства отмечался как гений с биполярным расстройством. Прозорливый ребенок, проводил эксперименты, что вызывало у небожителей раздражение. Кощей — изгой с детства. Пересвет — крепкий юноша, в силу характера поддевал и насмеxался над Кощеем. Обидевшись на весь мир, Кощей сбежал родителей. Кощей разгадал секрет добычи производства драгоценных металлов и камней, С помощью науки и алxимии создал секрет бессмертия, применил на себе — стал неуязвим. Создал из обычного мифологического дракона, коих видел много от Скандинавии до Китая — сверхдракона — Змея Горыныча о трех головах. Мстил добру,  но внутри часто страдал от этого.</p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/koshey/koshey_separator.png" />
        <section className="visual visual_koshey">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/koshey/koshey-info-1.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_1">Незримый покровитель</h4>
                        <p>
                            Тесла, Эйнштейн, Попов, Циолковский и прочие первооткрыватели, те — кому Кощей телепатически передавал и передает свои знания, стал  их незримым покровителем.
                        </p>
                        <img src="public/images/heroes/koshey/koshey-info-2.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_2">Двигатель прогресса</h4>
                        <p>
                            При всей негативности — двигатель прогресса  и цивилизации. Биткоины, NFT социальные сети.
                        </p>
                        <img src="public/images/heroes/koshey/koshey-info-3.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/koshey/koshey-info-4.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/koshey/koshey-info-5.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_3">Теневой герой</h4>
                        <p>
                        Безжалостно убивает, также самоотвержанно спасает, теневой герой на сторону добра
и цивилизации. Пытается перетянуть на свою сторону вундеркинда — Зарю.  
                        </p>
                        <img src="public/images/heroes/koshey/koshey-info-6.webp" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        
                        <img src="public/images/heroes/koshey/koshey-info-7.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/koshey/koshey-info-8.webp" alt="" />
                    </div>
                </div>
            </div>
        </section>
       
        <br />
        <HeroBanner
            img_desktop="public/images/heroes/koshey/koshey-footer.png"
            img_tablet="public/images/heroes/koshey/koshey-footer.png"
            img_mobile="public/images/heroes/koshey/koshey-footer.png"
        />
    </>
}

export default HeroKoshey