import React from 'react';
import Footer from '../layouts/Footer';
import HeroGorinich from '../widgets/HeroBlock/gorinich';
import Menu from '../widgets/Menu';

function GorinichPage(props) {
    return <>
        <HeroGorinich />
        <Menu />
        <Footer />
    </>
}

export default GorinichPage