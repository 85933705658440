import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import Footer from '../layouts/Footer';
import Heading from '../widgets/Heading';
import UniverseBlock from '../widgets/UniverseBlock';

function UniversePage(props) {
    const heroes = [
        {
            img: 'public/images/konek.svg',
            title: "public/images/konek_heading.svg",
            bg: "public/images/konek_bg1.png",
            description: "Стремительный и добрый дух, экстремал и спортсмен. Живет не в сказке, а в мегаполисе — познакомьтесь с Коньком!",
            url: '/konek',
            theme: 'universe_reverse'
        },
        {
            img: 'public/images/ermak.svg',
            title: "public/images/ermak_heading.svg",
            bg: "public/images/ermak_bg.png",
            description: "Отчаянный атаман, мастер единоборств, строитель и знаток военного дела — герой России!",
            url: '/ermak',
        },
        {
            img: 'public/images/leshiy.svg',
            title: "public/images/leshiy_heading.svg",
            bg: "public/images/leshiy_bg.png",
            description: "Вечный изгой эпоса и мифологии не так-то прост.",
            url: '/leshiy',
            theme: 'universe_reverse'
        },
        {
            img: 'public/images/zarya.svg',
            title: "public/images/zarya_heading.svg",
            bg: "public/images/zarya_bg.png",
            description: "Девочка внеземного происхождения, хайтек-уникум и кумир своего поколения!",
            url: '/zarya',
        },
        {
            img: 'public/images/yaga.svg',
            title: "public/images/yaga_heading.svg",
            bg: "public/images/yaga_bg.png",
            description: "Хранительница родной земли, мудрый специалист по ЗОЖ и яростная воительница. Встречайте новое прочтение знакомого сказочного образа!",
            url: '/yaga',
            theme: 'universe_reverse'
        },
        {
            img: 'public/images/peresvet.svg',
            title: "public/images/peresvet_heading.svg",
            bg: "public/images/peresvet_bg.png",
            description: "Полумифический исторический монах Пересвет, герой Куликовской битвы.",
            url: '/peresvet',
        },
        {
            img: 'public/images/vasilisa.svg',
            title: "public/images/vasilisa_heading.svg",
            bg: "public/images/vasilisa_bg.png",
            description: "В древней русской мифологии Василиса Прекрасная или Премудрая дочь морского царя.",
            url: '/vasilisa',
            theme: 'universe_reverse'            
        },
        {
            img: 'public/images/koshey.svg',
            title: "public/images/koshey_heading.svg",
            bg: "public/images/koshey_bg.png",
            description: "Механика, инженерия, нанотехнологии, алхимия, прочие новые метанауки - слабость Кощея.",
            url: '/koshey',
        },
           {
            img: 'public/images/gorinich.svg',
            title: "public/images/gorinich_heading.svg",
            bg: "public/images/gorinich_bg.png",
            description: "Горыныч был создан из трёх потерянных душ и сущностей людей.",
            url: '/gorinich',
            theme: 'universe_reverse'
        },
    ]

    return <>
        <Promo breakpoints={{
                desktop: {
                    type: 'video',
                    url: "public/videos/main.mp4"
                },
                tablet: {
                    type: 'image',
                    url: "public/images/unvrsBanner.jpg"
                },
                mobile: {
                    type: 'image',
                    url: "public/images/unvrsBanner.jpg"
                },
            }}
        />
        <Heading title="Герои BY Unvrs" />
        {heroes.map((el) => {
            return <UniverseBlock
                title={el.title}
                description={el.description}
                theme={el.theme}
                url={el.url}
                img={el.img}
                bg={el.bg}
                class="hero_universe"
            />
        })}
        <Menu />
        <Footer />
    </>
}

export default UniversePage