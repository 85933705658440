import React from "react";
import './index.scss'
import TG from '../../../assets/images/tg.svg'
import VK from '../../../assets/images/vk.svg'
// import OK from '../../../assets/images/ok.svg'
import Mail from '../../../assets/images/mail.svg'
// import Phone from '../../../assets/images/phone.svg'
import { Link } from "wouter";
// import Arrow from '../../../assets/images/arrow-right.svg'

function Footer() {
    const socialLinks = [
        {
            alt: 'Telegram',
            icon: TG,
            url: 'https://t.me/BYworldRU'
        },
        {
            alt: 'VK',
            icon: VK,
            url: 'https://vk.com/by.worldru'
        },
        // {
        //     alt: 'OK',
        //     icon: OK,
        //     url: '/'
        // },
    ]
    const contacts = [
        {
            icon: Mail,
            title: "team@by-world.ru",
            href: "mailto: team@by-world.ru"
        },
        // {
        //     icon: Phone,
        //     href: "tel:88005050909",
        //     title: "8(800)-505-0909"
        // },
    ]

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="footer__content">
                        <div className="footer__socials">
                            {socialLinks.map((social) => {
                                return <a href={social.url} className="footer__social-link">
                                    <img src={social.icon} alt={social.alt} />
                                </a>
                            })}
                        </div>

                        <div className="footer__contacts">
                            {/* <p className="footer__copyright mb-2">
                                st. 3rd backbone 20B, Москва, 109012
                            </p> */}
                            {contacts.map((el) => {
                                return <a href={el.href} className="footer__contacts-item">
                                    <div className="footer__contact-item-icon">
                                        <img src={el.icon} alt="" />
                                    </div>
                                    <span>{el.title}</span>
                                </a>
                            })}
                            <p className="footer__copyright">
                                Ⓒ BY World, 2024. Все права защищены
                            </p>
                        </div>
                        <Link href="/"><img className="footer__logo" src="public/images/BYFooter.png" alt="" /></Link>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
