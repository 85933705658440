import React from "react";
import { Route } from "wouter";
import ElectronicPage from "../pages/ElectronicPage";
import MainPage from "../pages/MainPage"
import UniversePage from "../pages/UniversePage"
import CompanyPage from "../pages/CompanyPage"
import PartnersPage from "../pages/PartnersPage";
import SpecialProjectsPage from "../pages/SpecialProjectsPage";
import MotosportPage from "../pages/MotosportPage";
import FashionPage from "../pages/FashionPage";
import RyzhiiPage from "../pages/RyzhiiPage";
import PaRUSPage from "../pages/PaRUSPage";
import IvlevPage from "../pages/IvlevPage";
import KonekPage from "../pages/KonekPage";
import ZaryaPage from "../pages/ZaryaPage";
import KosheyPage from "../pages/KosheyPage";
import VasilisaPage from "../pages/VasilisaPage";
import GorinichPage from "../pages/GorinichPage";
import PeresvetPage from "../pages/PeresvetPage";
import ErmakPage from "../pages/ErmakPage";
import LeshiyPage from "../pages/LeshiyPage";
import YagaPage from "../pages/YagaPage";
import RedirectPage from "../pages/RedirectPage";

function Router() {
    return (
        <>
            <Route path="/">
                <MainPage />
            </Route>
            <Route path="/electronics">
                <ElectronicPage />
            </Route>
            <Route path="/partners">
                <PartnersPage />
            </Route>
            <Route path="/special-projects">
                <SpecialProjectsPage />
            </Route>
            <Route path="/by-motosport">
                <MotosportPage />
            </Route>
            <Route path="/fashion">
                <FashionPage />
            </Route>
            <Route path="/ryzhii">
                <RyzhiiPage />
            </Route>
            <Route path="/parus">
                <PaRUSPage />
            </Route>
            <Route path="/ivlev">
                <IvlevPage />
            </Route>
            <Route path="/electronics">
                <ElectronicPage />
            </Route>
            <Route path="/company">
                <CompanyPage />
            </Route>
            <Route path="/universe">
                <UniversePage />
            </Route>
            <Route path="/zarya">
                <ZaryaPage />
            </Route>
            <Route path="/konek">
                <KonekPage />
            </Route>
            <Route path="/peresvet">
                <PeresvetPage />
            </Route>
            <Route path="/ermak">
                <ErmakPage />
            </Route>
            <Route path="/leshiy">
                <LeshiyPage />
            </Route>
            <Route path="/yaga">
                <YagaPage/>
            </Route>
            <Route path="/koshey">
                <KosheyPage />
            </Route>
            <Route path="/vasilisa">
                <VasilisaPage />
            </Route>
            <Route path="/gorinich">
                <GorinichPage />
            </Route>
            <Route path="/by-shop-app">
                <RedirectPage />
            </Route>
        </>
    )
}

export default Router