import React, { useState } from 'react';
import './index.scss'
import classNames from "classnames";
import useLocation from "wouter/use-location";

function Menu() {
    const [location] = useLocation();
    const [show, setShow] = useState(false);
    const list = [
        {
            name: "О компании",
            url: "/company"
        },
        {
            name: "Проекты BY",
            url: "/#world"
        },
        {
            name: "Вселенная BY",
            url: "/#universe"
        },
        {
            name: "BY Shop",
            url: "https://by-shop.ru",
            target: "_blank"
        },
    ]
    return <header className="menu">
        <div className="container">
            <div className="menu__content" onClick={() => { setShow(!show) }}>
                <button className={classNames({
                    "menu__toggler": true,
                    "menu__toggler_show": show,
                })}
                >
                    <span /><span /><span />
                </button>
            </div>
        </div>

        <div className={classNames({
            "menu__list": true,
            "menu__list_show": show,
        })}
        >
            {list.map((el) => {
                return !el?.target ? <a href={el.url}
                    className={classNames({
                        "menu__link": true,
                        "menu__link_disabled": el.url === location,
                    })}
                    onClick={() => setShow(!show)}
                >
                    {el.name}
                </a> : <a href={el.url}
                    target="_blank"
                    rel="noreferrer"
                    className={classNames({
                        "menu__link": true,
                        "menu__link_disabled": el.url === location,
                    })}
                    onClick={() => setShow(!show)}
                >
                    {el.name}
                </a>
            })}
        </div>
    </header>
}

export default Menu