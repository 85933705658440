
import React from 'react'
import './index.scss'
import SwiperCore, { Navigation, Pagination } from "swiper/core"
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from 'react-player'
import { useWindowSize } from '../../../utils';


function Promo(props) {
    SwiperCore.use([Navigation, Pagination]);
    const { breakpoints } = props;
    const size = useWindowSize()[0];

    const renderItem = (item) => {
        if (item.type === 'video') {
            return <ReactPlayer
                url={[{
                    src: item.url,
                    type: 'video/mp4',
                }]}
                width="100%"
                height="100%"
                playing={true}
                muted
                loop
            />
        } else {
            return <img src={item.url} alt="" />
        }
    }
    const renderContent = (windowSize) => {
        if (windowSize > 992) {
            return renderItem(breakpoints.desktop)
        }
        if (windowSize > 767) {
            return renderItem(breakpoints.tablet)
        }
        if (windowSize < 768) {
            return renderItem(breakpoints.mobile)
        }
        return props.img_desktop;
    };
    return (
        <section className="promo" id="promo">
            <div className="container-fluid">
                <div className="promo__slide">
                    {/* <a href="company" className="promo__link" /> */}
                    {breakpoints && renderContent(size)}
                    {props?.img && <img src={props.img} alt="" />}
                    {props?.video && <ReactPlayer
                        url={[{
                            src: props.video,
                            type: 'video/mp4',
                        }]}
                        width="100%"
                        height="100%"
                        playing={true}
                        muted
                        loop
                    />}
                </div>
                {/* </SwiperSlide>
                </Swiper> */}
            </div>
        </section >
    )
}

export default Promo
