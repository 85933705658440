import React from 'react';
import './index.scss';
import HeroBanner from '../HeroBanner';
import './yaga.scss'
import HeroSeparator from '../HeroSeparator';
function HeroYaga(props) {

    return <>
    <HeroBanner
            img_desktop="public/images/heroes/yaga_desktop.jpg"
            img_tablet="public/images/heroes/yaga_desktop.jpg"
            img_mobile="public/images/heroes/yaga_mobile.jpg"
        />
        <section className="yaga-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga_skills.png" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>ОБЛАДАЕТ СЕКРЕТАМИ ВЕЧНОЙ МОЛОДОСТИ И БЕССМЕРТИЯ</li>
                            <li>ЦЕЛИТЕЛЬНИЦА, ТРАВНИЦА</li>
                            <li>НЕВЕДОМАЯ РАЗРУШИТЕЛЬНАЯ СИЛА</li>
                            <li>ПОКОРЕНИЕ СТИХИЙ, ПРИРОДЫ ДУХОВ</li>
                            <li>УСИЛЕНИЕ ИЛИ ОСЛАБЛЕНИЕ СУПЕРСПОСОБНОСТЕЙ ПРОЧИХ ГЕРОЕВ</li>
                            <li>ОГУЧАЯ СИЛА ВНУШЕНИЯ</li>
                            <li>ЛЕВИТАЦИЯ</li>
                            <li>СВЕРХГОЛОС</li>
                            <li>ДАР ВИДЕНИЙ</li>
                            <li>ПЕРЕВОПЛОЩЕНИЕ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/yaga/yaga_separator.png" />
        <section className="history history_konek">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Мать Земли, так уважительно называют Ягу герои вселенной BY. Красивая, мудрая женщина, всегда  предстающая в дорогих одеяниях, центральный персонаж вселенной, для кого-то Ведьма — Ведущая Мать,  а для кого-то — спасение и справедливость! Появилась из глубокой древности, имеет дар к вечной  молодости и целительству, управляет стихиями, перевоплощается во все живое и неживое, обладает  знаниями левитации и могучей силой внушения. Может как оказать теплый прием в доме, так и дать в  одиночку отпорцелой армиии или нежелательному гостю. Вселенная BY возрождает и развивает истинный образ величайшего персонажа славянской мифологии, созданный далекими поколениями.</p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/yaga/yaga_separator.png" />
        <section className="yaga-info">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga-info-1.png" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_1">Княгиня Яльга</h4>
                        <p>Молодая красивая княгиня Яльга значительного по территории государства, будучи еще язычницей, обладает секретами вечной молодости, целительством, знанием стихий, трав, была невероятно мудра и контактировала с языческими богами и духами.
                            После убийства ее мужа князя врагами, Яльга своей неведомой разрушительной силой отомстила недругам за смерть супруга.</p>
                        <img src="public/images/heroes/yaga/yaga-info-2.png" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_2">Образ княгини</h4>
                        <p>Иногда изображена богатыркой, воительницей, она искусна в бою, стрельбе. беге, ездит на коне.
                            В народе ее звали «святой воительницей».</p>
                        <img src="public/images/heroes/yaga/yaga-info-3.png" className="w-80" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga-info-4.png" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga-info-5.png" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4>Появление «Бабы-Яги»</h4>
                        <p>Яльга уходит глубоко в леса, отрекается от людей и продолжает саморазвитие. Люди преследуют ее, не понимая ее величия, однажды в переделке с ними, она теряет ногу по колено и ставит основание из кости оленя. Знания вечной молодости и бессмертия позволяют ей стать бессмертной.
                            Нарекают мать Яльгу – «Бабой-Ягой - костяной ногой», сменившей свои вероучения и отрекшейся
                            от богов. Так в народе вживается образ опасной, злой женщины – колдуньи, живущей в лесу. </p>
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_3">Ведущая Мать</h4>
                        <p>Для многих из леса приходят вести о некой строгой
                            и справедливой Ведьме – которую народ
                            с уважением величает не иначе как Ведущая Мать. Красивая от природы женщина в самом рассвете сил со сложным надломленным характером
                            и неоднозначно тяжелой недооцененной судьбой
                            и вкладом в историю страны и мира продолжает свой путь. Создав непроходимое пространство
                            к своему жилищу, Яльга пропускает к себе только страждущих людей. Учит их знаниям, дает силу.</p>
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga-info-6.png" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga-info-7.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_4">Встреча с Пересветом</h4>
                        <p>Среди таких героев на пути Яльги попадается заблудший полубог Пересвет, которому Яльга дает знания, раскрывает его божественный потенциал
                            и отправляет на усмирение в монастырь. Далее их дороги будут пересекаться не раз.</p>
                        <img src="public/images/heroes/yaga/yaga-info-8.jpg" className="w-80" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_5">Приемная дочь</h4>
                        <p>Одну из «дочерей» Баба-Яга находит маленьким брошенным ребенком в лесу, ниспосланным свыше, она воспитывает младенца, нарекает Зарей, передает знания и видит в ней могущественную инородную силу.</p>
                        <img src="public/images/heroes/yaga/yaga-info-9.jpg" className="w-80" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga-info-10.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">

                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga-info-11.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/yaga/yaga-info-12.jpg" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <HeroBanner
            img_desktop="public/images/heroes/yaga/yaga-footer.png"
            img_tablet="public/images/heroes/yaga/yaga-footer.png"
            img_mobile="public/images/heroes/yaga/yaga-footer.png"
        />
    </>
}

export default HeroYaga