import React from 'react';
import './index.scss';
import classNames from "classnames";
import { Link } from 'wouter';

function Hero(props) {
    return <section
        className={classNames({
            "hero": true,
            [props.theme]: !!props.theme,
            [props.class]: !!props.class
        })}
    >
        <div className="container">
            <div className="hero__content">
                <div className="hero__info">
                    <h6 className="hero__title">{props.title}</h6>
                    <p className="hero__description">{props.description}</p>
                    {props.url && <Link href={props.url} className="hero__link">
                        Подробнее
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-3.0598e-07 8L16 8M16 8L9 15M16 8L9 1" stroke="#141414" />
                        </svg>
                    </Link>}
                </div>
                <img src={props.img} alt="" className="hero__image" />
            </div>
        </div>
    </section>
}

export default Hero