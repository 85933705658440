import React from 'react'

function RedirectPage() {

    return (
        <div>...

            <div style={{ opacity: 0 }}>{window.location = 'https://by-shop.onelink.me/stuF?pid=qr-code&c=ntg_noshop_by-shop-main&af_channel=mysite'}
            </div>
        </div>
    )
}

export default RedirectPage