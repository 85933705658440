import React from 'react';
import './index.scss';
import './ermak.scss';
import HeroBanner from '../HeroBanner';
import HeroSeparator from '../HeroSeparator';

function HeroErmak(props) {
    return <>
       <HeroBanner
            img_desktop="public/images/heroes/ermak_desktop.jpg"
            img_tablet="public/images/heroes/ermak_desktop.jpg"
            img_mobile="public/images/heroes/ermak_mobile.jpg"
        />
        <section className="ermak-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col hero-row__col_fluid">
                        <img src="public/images/heroes/ermak/ermak-skills.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>СУПЕРЗНАНИЯ И НАВЫКИ В ОБЛАСТИ ИНЖЕНЕРИИ, ИННОВАЦИЙ И АРХИТЕКТУРЫ</li>
                            <li>МАСТЕР ЕДИНОБОРСТВ</li>
                            <li>ВЫЗЫВАЕТ СЛОВОМ ТЁМНЫХ ДУХОВ, СПОСОБНЫХ СМЫТЬ НА СВОЕМ ПУТИ ВСЕ ЖИВОЕ И НЕЖИВОЕ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/ermak/ermak_separator.jpg" />
        <section className="history history_ermak">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Отчаянный атаман, умный воевода, строитель, зодчий, прекрасный знаток военного дела — герой России. Воевал, защищая Русь в Ливонской войне от литовцев и шведов. Главной и основной заслугой считается  завоевание атаманом Ермаком сибирских земель. Хирость, смекалка и современные по тем временам  технологии. Окутано тайной его появление, но известно что помог Ермак самому Илье Муромцу, поднял с печи и помог с обретением неслыханной силы. Спрашивали, откуда такая сила и отшучивался Ермак от людей: мол Яга его всему научила и подарила инструмент особый. Говорил, мол муж он ее. И дочка у него от Бабы-Яги, Василисой зовут, рассказывал.</p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/ermak/ermak_separator.jpg" />
        <section className="ermak-info">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/ermak/ermak-info-1.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_1">Призыв темных духов</h4>
                        <p>Все историки утверждают, основываясь на первоисточниках (письмах, донесениях), что обладал он могучей силой и знался с магией. В самый важный момент боя ниоткуда на помощь к нему приходила черная рать или как называли по-другому темные духи. «Как потоком воды смывало врага при их появлении»</p>
                        <img src="public/images/heroes/ermak/ermak-info-2.jpg" className="w-80" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_2">Техническое оснащение</h4>
                        <p>Хитрость, смекалка и современные по тем временам технологии: пушками и пищалями на кораблях отбивал Ермак земли по воде, по рекам от иноземцев.</p>
                        <img src="public/images/heroes/ermak/ermak-info-3.jpg" className="w-80" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/ermak/ermak-info-4.jpg" alt="" />
                    </div>
                </div>
                
                <div className="hero-row">
                    <div className="hero-row__block">
                        <img src="public/images/heroes/ermak/ermak-info-5.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/ermak/ermak-info-6.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/ermak/ermak-info-7.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__block">
                        <img src="public/images/heroes/ermak/ermak-info-8.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__block">
                        <img src="public/images/heroes/ermak/ermak-info-9.jpg" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <br />
        <HeroBanner
            img_desktop="public/images/heroes/ermak/ermak-footer.png"
            img_tablet="public/images/heroes/ermak/ermak-footer.png"
            img_mobile="public/images/heroes/ermak/ermak-footer.png"
        />
    </>
}

export default HeroErmak