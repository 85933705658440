import React from 'react';
import './index.scss';
import './peresvet.scss';
import HeroBanner from '../HeroBanner';
import HeroSeparator from '../HeroSeparator';

function HeroPeresvet(props) {

    return <>
        <HeroBanner
            img_desktop="public/images/heroes/peresvet_desktop.jpg"
            img_tablet="public/images/heroes/peresvet_desktop.jpg"
            img_mobile="public/images/heroes/peresvet_mobile.jpg"
        />
        <section className="peresvet-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col hero-row__col_fluid">
                        <img src="public/images/heroes/peresvet/peresvet-skills.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>НЕДЮЖАЯ ФИЗИЧЕСКАЯ СИЛА</li>
                            <li>ИЗЛУЧАЕТ МОЩНУЮ СВЕТОВУЮ ЭНЕРГИЮ, СПОСОБНУЮ РАЗРУШАТЬ ВСЕ СУЩЕЕ, ИЗЛЕЧИВАТЬ И СПАСАТЬ</li>
                            <li>ВОРОН ОРЛОВСКИЙ КОНЬ “ОГОНЬ” – СПУТНИК И ПОМОШНИК ПЕРЕСВЕТА В БОЮ</li>
                            <li>“ГЛАЗАМИ” ЯВЛЯЕТСЯ СТЕПНОЙ СОКОЛ/ИННОВАЦИОННЫЙ КОПТЕ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/peresvet/peresvet-separator.jpg" />
        <section className="history history_peresvet">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Полусифический исторический персонаж монах Пересвет, герой Куликовской битвы, появился во  вселенной BY неслучайно. Летописцы знали, что он полубог, сын Даждьбога, отвечавшего за солнечный свет. С малых лет Даждьбог говорил ребенку о сверхспособностях сына. Но кроме недюжей физической силы, котрой Пересвет пользовался не по назначению, никаких способностей у Пересвета замечено не было.  На пути пересвета встречается Яга, которая убеждает Пересвета уйти в монастырь - побыть наедите  с собой, ощутить мир. Пересвет становится лучшим учеником в монастыре, у него просыпается сверхсила. Пересвет излучает мощную световую энергию, способную разрушать или излечивать все сущее. После  Куликовской битвы Пересвет исчезает, станствует по миру, пройдет более ста войни столкновений в роли содата. Вселенная BY нашла супергероя, вернула ему славу защитника, сменив одеяния монаха на  супертехнологичный костюм</p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/peresvet/peresvet-separator.jpg" />
        <section className="peresvet-info">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/peresvet/peresvet-info-1.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_1">Боги Солнца</h4>
                        <p>В славянском эпосе в пантеоне богов существовало два, а то и три бога Солнца.
                            Даждьбог – отвечал за солнечный свет, Хорс – отве-чал за солнечный диск.</p>
                        <img src="public/images/heroes/peresvet/peresvet-info-2.jpg" className="w-100" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_2">Путь монаха</h4>
                        <p>Пересвет смирился, проводит много время, учится  обуздывать свою физическую мощь, тренирует разум и внутренний ресурс. </p>
                        <img src="public/images/heroes/peresvet/peresvet-info-3.jpg" className="w-80" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/peresvet/peresvet-info-4.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/peresvet/peresvet-info-5.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_3">Верные друзья</h4>
                        <p>Верным другом Пересвета является его конь Огонь. Вороной орловский рысак - добрый спутник  и помощник Пересвета в бою и обычной жизни.  «Глазами» Пересвета является еще один друг  степной сокол.</p>
                        <img src="public/images/heroes/peresvet/peresvet-info-6.jpg" className="w-100" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_4">Наше время</h4>
                        <p>Пересвет становится супергероем, появляется технологичный костюм. Верный конь Огонь превращается в стильный спортивный футуристический мотоцикл, а степного сокола заменяет инновационный коптер с мощными оптическими, оперативными системами защиты и противодействия. </p>
                        <img src="public/images/heroes/peresvet/peresvet-info-7.jpg" className="w-80" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/peresvet/peresvet-info-8.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/peresvet/peresvet-info-9.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/peresvet/peresvet-info-10.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/peresvet/peresvet-info-11.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/peresvet/peresvet-info-12.jpg" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <br />
        <HeroBanner
            img_desktop="public/images/heroes/peresvet/peresvet-footer.png"
            img_tablet="public/images/heroes/peresvet/peresvet-footer.png"
            img_mobile="public/images/heroes/peresvet/peresvet-footer.png"
        />
    </>
}

export default HeroPeresvet