import React from 'react';
import './index.scss'

function Heading(props) {
    return <div className="container"><h2 className="heading">
        <span>
            {props.title}
        </span>
    </h2>
    </div>
}

export default Heading