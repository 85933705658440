import React from 'react';
import './index.scss';
import './konek.scss';
import HeroBanner from '../HeroBanner';
import HeroSeparator from '../HeroSeparator';

function HeroKonek(props) {

    return <>
     <HeroBanner
            img_desktop="public/images/heroes/konek_desktop.jpg"
            img_tablet="public/images/heroes/konek_desktop.jpg"
            img_mobile="public/images/heroes/konek_mobile.jpg"
        />
        <section className="konek-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col hero-row__col_fluid">
                        <img src="public/images/heroes/konek/skills.png" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>ОБРАЩАЕТСЯ В БЫСТРЫХ И СИЛЬНЫХ ЖИВОТНЫХ</li>
                            <li>НЕЧЕЛОВЕЧЕСКАЯ СКОРОСТЬИ КООРДИНАЦИЯ</li>
                            <li>ПЕРЕНОСИТСЯ В ПРОСТРАНСТВЕ СО СКОРОСТЬЮ ВЕТРА</li>
                            <li>СПОСОБНОСТЬ ОСТАНАВЛИВАТЬ ВРЕМЯ НА 5 СЕКУНД ЗА СЧЕТ СКОРОСТИ И ЛОВКОСТИ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/konek/konek_separator.png" />
        <section className="history history_konek">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Конёк-Горбунок - герой российских сказаний во вселенной BY! Первое появление датируется на террито-рии Древней Индии или Китая. Впитал в себя способнсть реинкарнаций, восточных практик, цикличность,  фрактальность, всегда помогал людям в образах различных животных от горной кошки до Конька-Горбунка. В нынешней инкарнации выбрал себе форму и внешность, которую считал самой подвижной, ловкой  и интеллектуальной — человек! В настоящее время — паркурщик, быстро перемещается по мегаполису, также фанат всех скоростных, экстремальных и городских видов спорта. Носит маску, которая всегда меня-ется под стиль одежды, нечеловеческая скорость, координация, способность останавливать и опережать фрагменты временина несколько секунд. Изменяет формы или длины рук и ног, используя это как в жизни,  так и в соревнованиях.</p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/konek/konek_separator.png" />
        <section className="visual visual_konek">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/konek/visual-konek-1.webp" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4>Внешний облик</h4>
                        <p>
                            В текущей инкарнации выбрал себе форму и внешность, которую считал самой подвижной
                            и ловкой но не примечательной – возможно, крепкий слегка согнутый в плечах юноша. Как дух, стремящийся к добру, получил приятные человеческие черты лица.
                        </p>
                        <img src="public/images/heroes/konek/visual-konek-2.webp" alt="" />
                    </div>
                </div>
                <div className="visual-grid">
                    <img src="public/images/heroes/konek/visual-konek-3.webp" alt="" className="visual-grid__item" data-number="1" />
                    <img src="public/images/heroes/konek/visual-konek-4.webp" alt="" className="visual-grid__item" data-number="2" />
                    <img src="public/images/heroes/konek/visual-konek-5.webp" alt="" className="visual-grid__item" data-number="3" />
                    <img src="public/images/heroes/konek/visual-konek-6.webp" alt="" className="visual-grid__item" data-number="4" />
                </div>
            </div>
        </section>
        <section className="konek-info">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_1">В настоящее время</h4>
                        <p>Паркурщик, быстро перемещается по мегаполису (ямакаси), также фанат всех скоростных
                            и экстремальных видов спорта. Старается не выделяться сильно среди людей, но любит помогать им, становясь ментором для тех,
                            в которых чувствует схожую с собой сущность стремления «навстречу ветру».</p>
                        <img src="public/images/heroes/konek/konek-info-1.png" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/konek/konek-info-2.png" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/konek/konek-info-3.png" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4>Паркур - его привычная манера передвижения</h4>
                        <p> Очень импатичен, всегда открыт к общению, сподвигает встреченных на пути персонажей и героев на подвиги и приключения. Счастлив, когда движется, бежит, тратит физические силы. Переселился в города, потому что воспринимает их как прекрасные зоны препятствий. </p>
                        <img src="public/images/heroes/konek/konek-info-4.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <br/>
        <HeroBanner
            img_desktop="public/images/heroes/konek/konek-footer.png"
            img_tablet="public/images/heroes/konek/konek-footer.png"
            img_mobile="public/images/heroes/konek/konek-footer.png"
        />
    </>
}

export default HeroKonek