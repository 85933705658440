import React from 'react';
import './index.scss';
import classNames from "classnames";
import { Link } from 'wouter';

function UniverseBlock(props) {
    return <section
        className={classNames({
            "universe": true,
            [props.theme]: !!props.theme,
            [props.class]: !!props.class
        })}
        style={{ backgroundImage: `url(${props.bg})`, backgroundSize: 'cover' }}
    >
        <div className="container">
            <div className="universe__content">
                <div className="universe__info">
                <img src={props.title} alt="" className="universe__title" />
                    <p className="universe__description">{props.description}</p>
                    {props.url && <Link href={props.url} className="universe__link">
                        Подробнее
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-3.0598e-07 8L16 8M16 8L9 15M16 8L9 1" stroke="#141414" />
                        </svg>
                    </Link>}
                </div>
                <img src={props.img} alt="" className="universe__image" />
            </div>
        </div>
    </section>
}

export default UniverseBlock