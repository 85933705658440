
import React, { useRef } from 'react'
import './index.scss'
import SwiperCore, { Navigation, Pagination } from "swiper/core"
import { Swiper, SwiperSlide } from "swiper/react";
import Arrow from '../../../assets/images/arrow-right.svg'
import 'react-image-shadow/assets/index.css';
import ReactPlayer from 'react-player';

function Videos(props) {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    SwiperCore.use([Navigation, Pagination]);


    return <section className="gallery">
        <div className="container">
            <h3 className="gallery__title">
                <span>
                    Видео
                </span>
            </h3>

            <Swiper
                spaceBetween={20}
                slidesPerView={3}
                loop
                scrollbar={{ draggable: false }}
                className="gallery__slider"
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: props.slides?.length > 1 ? 2 : 1,
                    },
                    991: {
                        slidesPerView: props.slides?.length > 1 ? 2 : 1,
                    },
                }}
            >
                {props.slides.map((el) => <SwiperSlide className="gallery__slide">
                    {/* <ImageShadow src={`public/images/gallery/${el}`} /> */}
                    <ReactPlayer
                        url={el}
                        playing={false}
                        // width="700px"
                        // height="500px"
                        controls
                        light
                    />
                </SwiperSlide>)}

                {props.slides?.length > 1 && <div className="gallery__arrows">
                    <div
                        className="gallery__arrow gallery__arrow_prev"
                        ref={navigationPrevRef}
                    >
                        <img src={Arrow} alt="" />
                    </div>
                    <div
                        className="gallery__arrow gallery__arrow_next"
                        ref={navigationNextRef}
                    >
                        <img src={Arrow} alt="" />
                    </div>
                </div>}
            </Swiper>
        </div>
    </section>
}

export default Videos