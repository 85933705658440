import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import RowText from '../widgets/RowText';
import IconsLinks from '../widgets/IconsLinks';
import Footer from '../layouts/Footer';

function PartnersPage(props) {

    return <>
        <Promo img="public/images/partners_banner.jpg" />
        <Menu />
        <RowText textColumns={[
            "Запускаем проекты и коллаборации со звездами, формируя новую поп-культуру страны. С нами работают Константин Ивлев, Павел Воля, Ляйсан Утяшева, Тимур Бекмамбетов, Андрей Григорьев-Апполонов («Рыжий»).",
            "Приоритет BY — работа с детьми и подростками, продюсирование талантов и поддержка креативных проектов. Вместе с нашими партнерами мы создаем вселенную для комфортной и яркой жизни тинейджеров и их родителей — вселенную BY.",
            "Мы интегрируем концепцию BY в важные для страны инициативы. Поддерживаем киноиндустрию, авто- и мотоспорт, боевые искусства, развиваем гастрономические проекты, запускаем благотворительные и социальные проекты."
        ]} title="Партнеры" />
        {/* <Gallery /> */}
        <IconsLinks />
        <Footer />
    </>
}

export default PartnersPage