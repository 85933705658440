import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import RowText from '../widgets/RowText';
import Gallery from '../widgets/Gallery';
import Footer from '../layouts/Footer';
import Videos from '../widgets/Videos';

function PaRUSPage(props) {

    return <>
        <Promo img="public/images/parus.jpg" />
        <Menu />
        <RowText textColumns={[
            "BY — официальный партнер самого крупного музыкального фестиваля на Ближнем Востоке. Международный open-air фестиваль ежегодно собирает в знаменитом Burj Al Arab (отеле «Парус») звезд шоу-бизнеса и спорта.",
            "Программа фестиваля PaRUS — уникальный микс музыки разных направлений, впечатляющего видеоряда, невероятного звука под открытым небом на берегу Персидского залива. Именно в PaRUS презентуют свои программы музыканты с мировым именем.",
            "Фишка PaRUS — бои MMA. На один день сцена превратится в спортивную арену, где будут состязаться ведущие бойцы смешанных единоборств. Азарт, напор, звуковое и визуальное сопровождение на пике цифровых технологий, желание удивить публику и превзойти ее ожидания — то, в чем неизменно сходятся BY и PaRUS."
        ]} title="PaRUS" />
        <Gallery slides={["parus/1.jpeg", "parus/4.jpeg", "parus/5.jpg", "parus/6.jpeg", "parus/7.jpg", "parus/8.jpg", "parus/10.jpeg", "parus/11.jpeg", "parus/12.jpeg", "parus/13.jpeg", "parus/14.jpeg"]} />
        <Videos slides={["https://www.youtube.com/embed/vG2-bu7IGnc", "https://www.youtube.com/embed/1hp5gc38Qrw", "https://www.youtube.com/embed/IWetnASS9Fg", "https://www.youtube.com/embed/DO0vama_PYc", "https://www.youtube.com/embed/4xXCFTMNp80", "https://www.youtube.com/embed/NPGZ0PoHdv8"]} />
        <Footer />
    </>
}

export default PaRUSPage