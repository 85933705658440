import React from 'react';
import { Link } from 'wouter';
import { useWindowSize } from '../../../utils';
import './index.scss'
function Info() {
    const size = useWindowSize()[0];
    const renderImage = (windowSize, el) => {
        if (windowSize > 767) {
            return el.desktop
        }
        if (windowSize < 768) {
            return el.mobile
        }
        return el.desktop;
    };

    const config = {
        data: [
            {
                title: "BY",
                background: {
                    desktop: 'by.jpg',
                    mobile: 'by_mobile.jpg'
                },
                links: [
                    {
                        title: "О КОМПАНИИ",
                        url: '/company'
                    },
                    {
                        title: "ПАРТНЕРЫ",
                        url: '/partners'
                    }
                ]
            },
            {
                title: "Проекты BY",
                background: {
                    desktop: 'world.jpg',
                    mobile: 'world_mobile.jpg'
                },
                id: "world",
                links: [
                    {
                        title: "ENTERTAINMENT",
                        url: '/special-projects'
                    },
                    {
                        title: "BY Motorsport",
                        url: '/by-motosport'
                    },
                    {
                        title: "FASHION",
                        url: '/fashion'
                    },
                    {
                        title: "РЫЖИЙ",
                        url: '/ryzhii'
                    },
                    {
                        title: "PaRUS",
                        url: '/parus'
                    },
                    {
                        title: "IVLEV CHEF",
                        url: '/ivlev'
                    },
                ]
            },
            {
                title: "Вселенная BY",
                id: "universe",
                background: {
                    desktop: 'universe.jpg',
                    mobile: 'universe_mobile.jpg'
                },
                links: [
                    {
                        title: "ГЕРОИ",
                        url: 'https://russiansuperheroes.ru',
                        target: "blank"
                    },
                    {
                        title: "ЗАРЯ",
                        url: 'https://russiansuperheroes.ru/universe/zarya/',
                        target: "blank"
                    },
                    {
                        title: "КОНЕК",
                        url: 'https://russiansuperheroes.ru/universe/konek/',
                        target: "blank"
                    },
                    {
                        title: "ПЕРЕСВЕТ",
                        url: 'https://russiansuperheroes.ru/universe/peresvet/',
                        target: "blank"
                    },
                    {
                        title: "ЯГА",
                        url: 'https://russiansuperheroes.ru/universe/yaga/',
                        target: "blank"
                    },
                    {
                        title: "ЛЕШИЙ",
                        url: 'https://russiansuperheroes.ru/universe/leshiy/',
                        target: "blank"
                    },
                    {
                        title: "ЕРМАК",
                        url: 'https://russiansuperheroes.ru/universe/ermak/',
                        target: "blank"
                    },
                    {
                        title: "ВАСИЛИСА",
                        url: 'https://russiansuperheroes.ru/universe/vasilisa/',
                        target: "blank"
                    },
                    {
                        title: "КОЩЕЙ",
                        url: 'https://russiansuperheroes.ru/universe/koshey/',
                        target: "blank"
                    },
                    {
                        title: "ГОРЫНЫЧ",
                        url: 'https://russiansuperheroes.ru/universe/gorynich/',
                        target: "blank"
                    },
                    {
                        title: "Сунь Шэн",
                        url: 'https://russiansuperheroes.ru/universe/sunshen/',
                        target: "blank"
                    },
                ]
            },
            {
                title: "BY Shop",
                background: {
                    desktop: 'shop.jpg',
                    mobile: 'shop_mobile.jpg'
                },
                links: [
                    {
                        title: "В МАГАЗИН",
                        url: 'https://by-shop.ru',
                        target: "blank"
                    },
                ]
            },
        ]
    }

    return <section className="info" >

        <div className="info__list">
            {config.data.map((el) => {
                return <div className="info__item" id={el.id}>
                    <div className="info__item-bg" style={{ backgroundImage: `url("public/images/${renderImage(size, el.background)}")` }} alt="" />
                    <div className="container">
                        <div className="info__item-title">
                            {el.title}
                        </div>
                        <div className="info__item-links">
                            {el.links.map((link) => {
                                if (link.target) {
                                    return <a href={link.url} className="info__item-link" target="_blank" rel="noreferrer">
                                        {link.title}
                                    </a>
                                }
                                return <Link href={link.url} className="info__item-link">
                                    {link.title}
                                </Link>
                            })}
                        </div>
                    </div>
                </div>

            })}
        </div>
    </section>
}

export default Info