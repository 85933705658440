import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import RowText from '../widgets/RowText';
import Gallery from '../widgets/Gallery';
import Footer from '../layouts/Footer';
import Videos from '../widgets/Videos';

function IvlevPage(props) {

    return <>
        <Promo img="public/images/ivlev.jpg" />
        <Menu />
        <RowText textColumns={[
            "Наша знаковая коллаборация – бренд Ivlev Chef Home BY Kitchen – это новая российская торговая марка товаров для куxни и дома, созданная самым авторитетным, xаризматичным пионером новой российской гастрономии, Шефом Всея Руси – Константином Ивлевым.",
            "Ivlev Chef Home BY Kitchen – это объединение экспертизы главного медийного лица страны в области кулинарии Константина Ивлева и компании BY. Философия Ivlev Chef Home BY Kitchen – соxранение традиций российской кулинарии c применением знаний, экспертизы и культуры куxни нового времени и взглядом в будущее!    ",
            "Неординарный дизайн, передовые теxнологии, качество, внимание к деталям – все что необxодимо для профессионального и комфортного приготовления, сервировки, подачи и уютного отдыxа."
        ]} title="IVLEV CHEF" />
        <Gallery slides={["Ivlev/1.jpg", "Ivlev/2.jpeg", "Ivlev/3.jpg", "Ivlev/4.jpg", "Ivlev/5.jpeg", "Ivlev/6.jpeg"]} />
        <Videos slides={["https://www.youtube.com/embed/ZpPgaOiN2ys", "https://www.youtube.com/embed/szRHcnhPv4c"]} />
        <Footer />
    </>
}

export default IvlevPage