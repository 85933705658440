import React from 'react';
import Footer from '../layouts/Footer';
import Info from '../widgets/Info';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';

function MainPage(props) {
    return <>
        <Promo breakpoints={{
            desktop: {
                type: 'video',
                url: "public/videos/main.mp4"
            },
            tablet: {
                type: 'image',
                url: "public/images/company_banner.jpg"
            },
            mobile: {
                type: 'image',
                url: "public/images/company_banner.jpg"
            },
        }} />
        <Menu />
        <Info />
        <Footer />
    </>
}

export default MainPage