import React from 'react';
import './index.scss'
function IconsLinks() {
    const links = [
        {
            url: "https://www.ivlevgroup.com",
            image: "public/images/partners/Ivlev.png"
        },
        {
            url: "https://zhara.tv/archives/category/festivals",
            image: "public/images/partners/zhara.png"
        },
        {
            url: "https://www.golden-ring.ru",
            image: "public/images/partners/zolotoe_kolzo.png"
        },
        {
            url: "https://parusfest.com/ru",
            image: "public/images/partners/parus.png"
        },
        {
            url: "https://bazelevs.ru",
            image: "public/images/partners/bazelevs.png"
        },
        {
            url: "https://creativityweek.ru ",
            image: "public/images/partners/RKN.png"
        },
        {
            url: "https://crocusgroup.ru",
            image: "public/images/partners/Krokus.png"
        },
        {
            url: "https://ru.riki.team",
            image: "public/images/partners/Riki.png"
        },
        {
            url: "https://www.skoltech.ru",
            image: "public/images/partners/Scoltech.png"
        },
        {
            url: "https://artmasters.ru/",
            image: "public/images/partners/artmasters.svg"
        },

    ]
    return <section className="icons-links">
        <div className="container">
            <div className="icons-links__list">
                {links.map((el) => {
                    return <a href={el.url} target="_blank" rel="noreferrer" className="icons-links__item">
                        <img src={el.image} alt="" />
                    </a>
                })}
            </div>
        </div>
    </section>
}

export default IconsLinks