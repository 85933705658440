import React from 'react';
import Footer from '../layouts/Footer';
import HeroKonek from '../widgets/HeroBlock/konek';
import Menu from '../widgets/Menu';

function KonekPage(props) {
    return <>
        <HeroKonek />
        <Menu />
        <Footer />
    </>
}

export default KonekPage