import React, { useEffect } from "react";
import Router from './jsx/core/router'
import useLocation from "wouter/use-location";
import TagManager from "react-gtm-module";

function App() {
  const [location] = useLocation();
  TagManager.initialize({
    gtmId: "GTM-KNJ9P5R",
  });
  useEffect(() => {
    if (window.location.href.includes("#")) {
      document.getElementById(window.location.href?.split('#')[1]).scrollIntoView()
      return
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  }, [location])
  return (
    <div className="page">
      <Router />
    </div>
  );
}

export default App;
