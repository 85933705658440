import React from 'react';
import './index.scss';
import "./zarya.scss"
import HeroBanner from '../HeroBanner';
import HeroSeparator from '../HeroSeparator';

function HeroZarya(props) {

    return <>
 <HeroBanner
            img_desktop="public/images/heroes/zarya_desktop.jpg"
            img_tablet="public/images/heroes/zarya_desktop.jpg"
            img_mobile="public/images/heroes/zarya_mobile.jpg"
        />
        <section className="zarya-skills">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-skills.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h3>СВЕРХСПОСОБНОСТИ</h3>
                        <ul>
                            <li>ПОДКЛЮЧАЕТСЯ И УПРАВЛЯЕТ ЛЮБЫМИ ТЕХЛОНОГИЧНЫМИ ГАДЖЕТАМИ И СИСТЕМАМИ</li>
                            <li>ГЕНЕРИРУЕТ ЭЛЕКТРИЧЕСКИЕ РАЗРЯДЫ</li>
                            <li>ВЛИЯЕТ НА ЭНЕРГЕТИЧЕСКОЕ И МАГНИТНОЕ ПОЛЕ ЗЕМЛИ</li>
                            <li>СТАВИТ БИОМАГНИТНОЕ ЗАЩИТНОЕ ПОЛЕ</li>
                            <li>ПЕРЕДВИГАЕТ ПРЕДМЕТЫ НА РАССТОЯНИИ</li>
                            <li>ЗАРЯЖАЕТ И РАЗРЯЖАЕТ ЭЛЕКТРИЧЕСТВОМ ЛОКАЦИИ ВПЛОТЬ ДО ПОЛНОГО BLACKOUT ЦЕЛЫХ ГОРОДОВ И ОДНАЖДЫ ДАЖЕ ВСЕЙ ПЛАНЕТЫ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/zarya/zarya-separator.png" />
        <section className="history history_zarya">
            <div className="container">
                <h3>ИСТОРИЯ ПОЯВЛЕНИЯ</h3>
                <p>Давным-давно, одним ранним утром, прогуливаясь на рассвете, Яга обнаружила недалеко от своего жилища в глубокой чаще маленькую девочку в металлическом коконе. Она же и дала девушке имя.  Соответствие имени и меткость нейминга от Яги для Зари укрепляется еще и тем фактом, что  девочка способна исторгать «небесные» сполоxи из глаз, сиять и слепить, словно само утреннее солнце.  С самого начала младенец проявлял нестандартные для тех времен способности. Вспышки, отводы молний, управление металлическими предметами на расстоянии и многое другое, что говорило о явной  неординарности ребенка. </p>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/zarya/zarya-separator.png" />
        <section className="zarya-info">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-1.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-2.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-3.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_1">Внешне оболочка обычного человека</h4>
                        <p>При этом обладает рядом неорганических элементов, таких как тончайшие оптико-волоконные жгуты-кабели вместо вен и артерий.  Ее мозг работает с удивительной, абсолютно  нехарактерной для Homo Sapiens скоростью.</p>
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-4.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-27.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-5.jpg" alt="" />
                        <h4 className="heading heading_gradient_2">Хайтек-уникум</h4>
                        <p>Заря живо интересуется и совершенствует новейшие технологии и гаджеты. Ведет собственные блоги
                            на современных платформах вроде TikTok, Instagram
                            и YouTube, в рамках которых прививает аудитории  интерес к чудесам и «волшебству» научпопа.</p>
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_3">Не забывает о развитии своих физических способностей</h4>
                        <p>Спорт, координация, скорость движений, боевые единоборства. Однажды посмотрев бой без правил, моментально впитала все знания древней традиции джиу-джитсу. </p>
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-6.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-7.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <h4 className="heading heading_gradient_4">С повышенным чувством справедливости</h4>
                        <p>Добрая, активная, эффектная, озорная, с xорошим чувством юмора, с тягой к знаниям, бойкая и непосредственная, умница. Однако чрезмерно импульсивная и из-за отстаивания справедливости может стать прямолинейной, на гране нарушения общепринятых правил.
                            Находится в поиске своего истинного происхождения, хотя Яга называет ее своей дочкой. </p>
                        <img src="public/images/heroes/zarya/zarya-info-8.jpg" alt="" />
                    </div>

                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/zarya/zarya-separator.png" />
        <section className="zarya-info zarya-info_black">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-9.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-10.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-11.jpg" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <HeroSeparator img="public/images/heroes/zarya/zarya-separator.png" />
        <section className="zarya-info">
            <div className="container">
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-12.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-13.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-14.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-15.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-16.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-17.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-18.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-19.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-20.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-21.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-22.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-23.jpg" alt="" />
                    </div>
                </div>
                <div className="hero-row">
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-24.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-25.jpg" alt="" />
                    </div>
                    <div className="hero-row__col">
                        <img src="public/images/heroes/zarya/zarya-info-26.jpg" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <HeroBanner
            img_desktop="public/images/heroes/zarya/zarya-footer.png"
            img_tablet="public/images/heroes/zarya/zarya-footer.png"
            img_mobile="public/images/heroes/zarya/zarya-footer.png"
        />
    </>
}

export default HeroZarya