import React from 'react';
import Menu from '../widgets/Menu';
import Promo from '../widgets/Promo';
import RowText from '../widgets/RowText';
import Gallery from '../widgets/Gallery';
import Footer from '../layouts/Footer';

function RyzhiiPage(props) {

    return <>
        <Promo img="public/images/ryzhii.jpg" />
        <Menu />
        <RowText textColumns={[
            "Коллекция мерча, одежды и товаров для дома от звездного бренда «Рыжий». Основатель бренда — самый улыбчивый рыжий страны, солист «Иванушек International» Андрей Григорьев-Апполонов. И творит он в коллаборации с BY.",
            "«Рыжий» в Сочи. Это и магазин для семейного шоппинга, и культовое место курортной столицы, где можно встретить самого Григорьева-Аполлонова. Вживую и на майках, сумках, комиксах, в настроении покупателей. Первый магазин сети — любимец публики, как и его основатель.",
            "«Рыжий» по всей России. Теперь с товарами бренда можно познакомиться в магазинах вашего города. Выбирайте товары для дома, подарки, модные мобильные аксессуары и игрушки. Подпевайте любимым хитам, пока смотрите и примеряете. Рыжим — постоянная скидка!"
        ]} title="Рыжий" />
        <Gallery slides={["ryzhii/1.jpg", "ryzhii/2.jpg", "ryzhii/3.jpg", "ryzhii/4.jpg", "ryzhii/5.jpeg", "ryzhii/6.jpg", "ryzhii/7.jpg", "ryzhii/8.jpeg", "ryzhii/9.jpg", "ryzhii/10.jpg"]} />
        <Footer />
    </>
}

export default RyzhiiPage