import React from 'react';
import Footer from '../layouts/Footer';
import HeroPeresvet from '../widgets/HeroBlock/peresvet';
import Menu from '../widgets/Menu';

function PeresvetPage(props) {
    return <>
        <HeroPeresvet />
        <Menu />
        <Footer />
    </>
}

export default PeresvetPage