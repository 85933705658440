import React from 'react';
import './index.scss';

function RowText(props) {
    const { title } = props;
    return <section className="text-row">
        <div className="container">
            <h2 className="text-row__title">{title}</h2>
            <div className="text-row__items">
                {props.textColumns.map((el) => {
                    return <div className="text-row__col">
                        {el}
                    </div>
                })}
            </div>
        </div>
    </section>
}

export default RowText