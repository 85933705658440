
import React from 'react'
import './index.scss';
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindowSize } from '../../../utils';


function HeroBanner(props) {
    const size = useWindowSize()[0];

    const renderImages = (windowSize) => {
        if (windowSize > 992) {
            return props.img_desktop;
        }
        if (windowSize > 767) {
            return props.img_tablet;
        }
        if (windowSize < 768) {
            return props.img_mobile;
        }
        return props.img_desktop;
    };
    return (
        <section className="hero-banner">
            <div className="container-fluid">
                <img src={renderImages(size)} alt="" />
            </div>
        </section >
    )
}

export default HeroBanner
